import React from 'react';

import history from './history';
import { auth } from '../config';
import Auth0Provider from '@/shared/auth0';

const withAuth = (WrappedComponent: any) => {
  function WithAuthWrapper(props: any) {
    const onRedirectCallback = (appState: any) => {
      history.push(appState && appState.targetUrl ? appState.targetUrl : '/');
    };

    return (
      <Auth0Provider
        domain={auth.domain}
        client_id={auth.clientId}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience={auth.audience}
        scope={auth.scope}
        cacheLocation="localstorage"
      >
        <WrappedComponent {...props} />
      </Auth0Provider>
    );
  }

  return WithAuthWrapper;
};

export default withAuth;
