import React from 'react';

import { Grid, Switch, Text } from '@lp/ds-next';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';

import { IPartnerV3, IPartnerV3Update } from '../../../models/partnerV3';

export const Toggles = ({
  partner,
  updatePartner,
}: {
  partner: IPartnerV3;
  updatePartner: (data: IPartnerV3Update) => Promise<{ success: boolean }>;
}) => {
  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updatePartner({
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid item mobile={12} tablet={4}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">
          <Text variant="titleL">Miscellaneous Controls</Text>
        </FormLabel>
        <FormGroup sx={{ mt: '1rem', gap: '0.5rem' }}>
          <Switch
            label="Code on the BOX"
            checked={partner.code_opening_enabled}
            onChange={onChange}
            name="code_opening_enabled"
          />

          <Switch
            label="Delivery key via SMS"
            checked={partner.sms_enabled}
            onChange={onChange}
            name="sms_enabled"
          />

          <Switch
            label="Remote control"
            checked={partner.remote_control_enabled}
            onChange={onChange}
            name="remote_control_enabled"
          />

          <Switch
            label="Update firmware"
            checked={partner.fw_update_enabled}
            onChange={onChange}
            name="fw_update_enabled"
          />

          <Switch
            label="Flight mode"
            checked={partner.flight_mode_enabled}
            onChange={onChange}
            name="flight_mode_enabled"
          />
        </FormGroup>
      </FormControl>
    </Grid>
  );
};
