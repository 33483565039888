export interface boxLifecycle {
  sub_status: any;
  id: string;
  status: string;
  createdBy: {
    id: string;
    email: string;
  };
  createdAt: number;
  parsedCreatedAt: string;
}

export type TLifecyclestatuses =
  | ''
  | 'DRAFT'
  | 'PURCHASED'
  | 'DEPLOYED'
  | 'ARRIVED'
  | 'ARCHIVED'
  | 'ERROR';

export const LIFECYCLE_STATUSES = [
  'INIT',
  'TABLET_IN_STOCK',
  'TABLET_IN_QUALITY_PRISON',
  'PRODUCT_IN_STOCK',
  'PRODUCT_IN_QUALITY_PRISON',
  'PRODUCT_IN_REWORK',
  'IN_CIRCULATION',
  'IN_BUSINESS',
  'OUT_OF_BUSINESS',
  'OUT_OF_CIRCULATION',
  'PRODUCT_IN_REPAIR',
  'PRODUCT_DESTROYED',
  'TABLET_REFURBISHED',
  'TABLET_DESTROYED',
  'LOST_CAUSE',
  'OUT_OF_SERVICE',
  'PRODUCT_IN_LITIGATION',
];

export const LIFECYCLE_SUB_STATUSES = {
  PRODUCT_IN_STOCK: ['TO_DISMANTLE', 'TO_REWORK', 'TO_END_SERVICE'],
  TABLET_IN_QUALITY_PRISON: ['TO_ANALYSE', 'TO_REFURBISH', 'TO_REUSE'],
  PRODUCT_IN_QUALITY_PRISON: [
    'TO_ANALYSE',
    'TO_DISMANTLE',
    'TO_REUSE',
    'TO_REWORK',
    'TO_END_SERVICE',
  ],
  IN_BUSINESS: [
    'DAMAGED',
    'RECALLED',
    'END_OF_CONTRACT',
    'SECURITY_LOCK',
    'FLAGGED',
    'INTERNAL_USE',
    'LITIGATION',
  ],
  OUT_OF_BUSINESS: ['TO_REUSE', 'TO_ANALYSE_LVL_1'],
  OUT_OF_CIRCULATION: [
    'TO_REPAIR',
    'TO_DISMANTLE',
    'TO_UPGRADE',
    'TO_ANALYSE_LVL_2',
    'TO_REUSE',
    'TO_END_SERVICE',
  ],
  PRODUCT_DESTROYED: ['TO_REUSE', 'TO_REFURBISH', 'TO_ANALYSE'],
  OUT_OF_SERVICE: ['TO_OWNER_KEEP', 'TO_DISMANTLE'],
};
