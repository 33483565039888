import React, { useEffect, useState } from 'react';

import {
  Grid,
  Select,
  Stack,
  Text,
  styled,
  InformationIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  useTheme,
} from '@lp/ds-next';
import { orderBy } from 'lodash';

import CardSimVersion from './SimVersionDetails';
import { useSim } from '../api/sim/getSim';
import { IHistoryVersion } from '../models/sim';
import Error from '@/components/Error';
import Loader from '@/components/Loader';

const InformationIconGreen = styled(InformationIcon)`
  color: ${({ theme }) => theme.palette.custom.primary[120]};
`;

const SimVersions = ({ box }) => {
  const { data: simInfo, isLoading, error } = useSim({ lpUI: box.lpUI });
  const theme = useTheme();

  const [asc, setAsc] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<string>('version');

  const [listHistoryVersions, setListHistoryVersions] = useState<
    IHistoryVersion[]
  >([]);

  useEffect(() => {
    const sortedArray = orderBy(
      simInfo?.sim.update_history.sim_updates,
      filterType,
      asc ? 'asc' : 'desc'
    );
    setListHistoryVersions(sortedArray);
  }, [asc, filterType, simInfo?.sim.update_history.sim_updates]);

  if (isLoading) {
    return <Loader size={24} />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Stack
      maxWidth="36rem"
      margin="1rem auto 2rem auto"
      borderRadius="0.625rem"
      border="0.5px solid"
      borderColor="custom.neutral.black.8"
      padding="0.8rem"
      spacing={2}
    >
      <Grid
        container
        justifyContent="space-between"
        gap={{ mobile: '0.6rem', tablet: '0' }}
      >
        <Grid item mobile={12} tablet={7}>
          <Text variant="titleL">Sim card versions</Text>
          <Stack direction="row" spacing={1} alignItems="center">
            <Text variant="titleXS">ICCID</Text>
            <Text variant="bodyTextS">{simInfo?.sim.iccid}</Text>
          </Stack>
        </Grid>
        <Grid
          item
          container
          mobile={12}
          tablet={5}
          alignItems="center"
          flexDirection="row"
          gap="0.75rem"
        >
          <Grid item minWidth="8.5rem">
            <Select
              data-testid="selectSortType"
              label="Sort by"
              options={[
                { label: 'Updated at', value: 'updated_at' },
                { label: 'Completed at', value: 'update_completed_at' },
                { label: 'Created at', value: 'created_at' },
                { label: 'Version', value: 'version' },
              ]}
              defaultValue={filterType}
              {...{
                onChange: ({ target: { value } }) => setFilterType(value),
              }}
            />
          </Grid>
          <Grid
            item
            display="flex"
            direction="row"
            alignItems="center"
            minWidth="2rem"
            sx={{ cursor: 'pointer' }}
            data-testid="reverseSortingDirection"
            onClick={() => setAsc((prevState) => !prevState)}
          >
            <Text variant="bodyTextXS">{asc ? 'ASC' : 'DESC'}</Text>
            {asc ? (
              <ChevronUpIcon
                style={{ width: '2.5rem' }}
                color={theme.palette.custom.primary[120]}
              />
            ) : (
              <ChevronDownIcon color={theme.palette.custom.primary[120]} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="column" spacing={2}>
        {listHistoryVersions.map((sim) => (
          <CardSimVersion
            key={`${sim.version}${sim.created_at}`}
            historyVersion={sim}
          />
        ))}
      </Stack>
      <a
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '0.5rem',
          alignItems: 'center',
        }}
        target="_blank"
        href="https://www.twilio.com/docs/iot/supersim/sim-settings/base-settings"
        rel="noreferrer"
      >
        <Text variant="bodyTextL">More information here</Text>
        <InformationIconGreen />
      </a>
    </Stack>
  );
};

export default SimVersions;
