import { useQuery } from '@tanstack/react-query';

import { ISimStatus } from '../../models/sim';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getSim = async ({
  lpUI,
  makeRequest,
}: {
  lpUI: string;
  makeRequest: any;
}): Promise<ISimStatus | undefined> => {
  const { data, error } = await makeRequest({
    path: `/products/${lpUI}/sim`,
  });
  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getSim;

type UseSimOptions = {
  lpUI: string;
  config?: QueryConfig<QueryFnType>;
};

export const useSim = ({ lpUI, config }: UseSimOptions) => {
  const [, makeRequest] = useImperativeRequestWrapper('deviceV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['sim', lpUI],
    queryFn: () => getSim({ lpUI, makeRequest }),
  });
};
