import { useCallback, useState } from 'react';

import { Form } from 'antd';
import { SchemaOf } from 'yup';

const getValidatorFn = (schema: SchemaOf<any>, path: string) => (_, value) =>
  schema.validateAt(path, value).then(() => Promise.resolve());

export const getValidatorAntdRules = (schema: SchemaOf<any>, path: string) => ({
  rules: [
    {
      validator: getValidatorFn(schema, path),
    },
  ],
});

const useAntdForm = () => {
  const [form] = Form.useForm();
  const [touched, setTouched] = useState<boolean>(false);

  const resetFields = useCallback(() => {
    form.resetFields();
    setTouched(false);
  }, [form]);

  const handleFieldsChanged = useCallback(() => {
    setTouched(form.isFieldsTouched());
  }, [form]);

  return { form, resetFields, handleFieldsChanged, isFieldsTouched: touched };
};

export default useAntdForm;
