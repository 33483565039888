import React from 'react';

import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import { IModifiedShipment } from '../models/shipment';
import TextInput from '@/components/forms/TextInput';
import InsuranceState from '@/components/InsuranceState';
import PillButton from '@/components/PillButton';

const ShipmentForm = ({
  shipment,
  disabled = true,
}: {
  shipment: IModifiedShipment;
  disabled?: boolean;
}) => {
  const {
    id,
    carrierCode,
    createdBy,
    deployedBy,
    parsedCreatedAt,
    parsedUpdatedAt,
    box,
    serviceLevel,
    partnerId,
    labelWeightInGrams,
    boxWeightInGrams,
    carrierTrackingCode,
    contractState,
    parcelRequestId,
    secursusParcelId,
  } = shipment;

  const isInsureBySecursus = () => {
    switch (contractState) {
      case 'DRAFT':
      case 'PURCHASED':
      case 'TRANSFERRED':
        return parcelRequestId !== '';

      case 'DEPLOYED':
      case 'ARRIVED':
        return parcelRequestId !== '' && secursusParcelId !== '';

      default:
        return false;
    }
  };

  return (
    <>
      <Row>
        <TextInput disabled label="Shipment-ID" name="shipmentId" value={id} />
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label="Carrier Reference number"
            name="carrierTrackingCode"
            value={carrierTrackingCode}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled
            label="Carrier Code"
            name="carrierCode"
            value={carrierCode}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label="Created at"
            name="parsedCreatedAt"
            value={parsedCreatedAt}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled
            label="Updated at"
            name="parsedUpdatedAt"
            value={parsedUpdatedAt}
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" justify="space-between">
        <Col span={shipment?.box?.id ? 8 : 12}>
          <TextInput
            disabled={disabled}
            label="Box-ID"
            name="boxId"
            value={box.id}
          />
        </Col>
        {shipment?.box?.id && (
          <Col span={4}>
            <PillButton>
              <Link to={`/products/${shipment.box.thingName}`}>
                Go to THE BOX
              </Link>
            </PillButton>
          </Col>
        )}
        <Col span={12}>
          <TextInput
            disabled={disabled}
            label="Service Level"
            name="serviceLevel"
            value={serviceLevel}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled={disabled}
            label="Label Weight (in grams)"
            name="labelWeightInGrams"
            value={labelWeightInGrams.toString()}
            type="number"
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled={disabled}
            label="Weight by Box (in grams)"
            name="boxWeightInGrams"
            value={boxWeightInGrams.toString()}
          />
        </Col>
      </Row>
      <Row align="middle" gutter={24}>
        <Col span={shipment?.createdBy ? 8 : 12}>
          <TextInput
            disabled
            label="Created By"
            name="createdBy"
            value={createdBy}
          />
        </Col>
        {shipment?.createdBy && (
          <Col span={4}>
            <PillButton>
              <Link to={`/lp-account/${shipment.createdBy}`}>
                To Creator LP Account
              </Link>
            </PillButton>
          </Col>
        )}
        <Col span={shipment?.partnerId ? 8 : 12}>
          <TextInput
            disabled
            label="Partner-ID"
            name="partnerId"
            value={partnerId}
          />
        </Col>
        {shipment?.partnerId && (
          <Col span={4}>
            <PillButton>
              <Link to={`/lp-village/partners/${shipment.partnerId}`}>
                To Partner
              </Link>
            </PillButton>
          </Col>
        )}
      </Row>
      {shipment?.deployedBy && (
        <Row align="middle" gutter={24}>
          <Col
            span={
              shipment?.deployedBy && shipment.deployedBy !== 'InternalEventBus'
                ? 8
                : 12
            }
          >
            <TextInput
              disabled
              label="Deployed By"
              name="deployedBy"
              value={deployedBy}
            />
          </Col>

          {shipment.deployedBy !== 'InternalEventBus' && (
            <Col span={4}>
              <PillButton>
                <Link to={`/lp-account/${shipment.deployedBy}`}>
                  To Deployer LP Account
                </Link>
              </PillButton>
            </Col>
          )}
          <Col span={8}>
            <InsuranceState secursusEnabled={isInsureBySecursus()} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ShipmentForm;
