import React, { useCallback, useState } from 'react';

import {
  ButtonV2 as Button,
  Stack,
  Text,
  LoopIcon,
  useTheme,
} from '@lp/ds-next';
import { useParams } from 'react-router-dom';

import useRemoteControl, {
  REMOTE_COMMAND_ERRORS,
} from './hook/useRemoteControl';
import { RemoteError } from './RemoteControlPanel';

type CardCommandProps = {
  cardContent: any;
  isDirectMode: boolean;
  isConnected: boolean;
  shipmentId: string;
  setError: (value: boolean) => void;
  setErrorMessage: (value: RemoteError) => void;
  setShowFinalNotice: (value: boolean) => void;
  //   getLockState: () => void;
};

export const CardCommand = ({
  cardContent,
  setShowFinalNotice,
  isDirectMode,
  isConnected,
  shipmentId,
  setErrorMessage,
  setError,
  //   getLockState,
}: CardCommandProps) => {
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();

  const { sendRemoteAction, getRemoteActionList } = useRemoteControl(id);

  const [commandLoader, setCommandLoader] = useState<boolean>(false);
  const [allowRefresh, setAllowRefresh] = useState<boolean>(false);

  const timeoutConfirmAction = useCallback(
    (command: any, delay: number) => {
      setTimeout(async () => {
        const listcommand = await getRemoteActionList(id);
        const lastCommand = listcommand[0];

        command.status = lastCommand.status;
        setCommandLoader(false);
        setAllowRefresh(true);
        // getLockState();

        if (
          lastCommand.type === 'COMMAND_TYPE_RECEIVED' &&
          lastCommand.status === 'SUCCEEDED'
        ) {
          setShowFinalNotice(true);
        }
      }, delay);
    },
    [getRemoteActionList, id, setShowFinalNotice]
  );

  // Run command action
  const handleClickAction = useCallback(
    async (command: any) => {
      setCommandLoader(true);

      const error = await sendRemoteAction(id, shipmentId, command.action);

      if (error) {
        if (REMOTE_COMMAND_ERRORS.COMMAND_ALREADY_RUN === error.message) {
          setErrorMessage({
            title: 'command',
            message: `Command ${command.action} is already running`,
          });
          setCommandLoader(false);
        }

        if (REMOTE_COMMAND_ERRORS.DIRECT_MODE_DISABLED === error.message) {
          setErrorMessage({
            title: `Connection lost during ${command.name} command`,
            message: 'Please refresh to resolve this or try later.',
          });
          setCommandLoader(false);
        }

        setError(true);

        return;
      }

      timeoutConfirmAction(command, 10000);
    },
    [
      sendRemoteAction,
      id,
      shipmentId,
      timeoutConfirmAction,
      setError,
      setErrorMessage,
    ]
  );

  // Refresh status command
  const handleClickReload = useCallback(
    async (command) => {
      setCommandLoader(true);

      timeoutConfirmAction(command, 1500);
    },
    [timeoutConfirmAction]
  );

  return (
    <Stack
      width="25.3125rem"
      padding=".875rem"
      borderRadius=".75rem"
      border=".0625rem solid"
      borderColor="custom.neutral.black.10"
    >
      <Stack alignItems="center">
        <Text variant="titleM" marginBottom=".25rem">
          {cardContent.title}
        </Text>
        <Text
          variant="bodyTextS"
          color="custom.neutral.black.50"
          marginBottom="1.5rem"
        >
          {cardContent.subtitle}
        </Text>
      </Stack>
      <Stack
        alignItems="center"
        minHeight="6rem"
        justifyContent="center"
        marginBottom=".75rem"
      >
        {commandLoader ? (
          cardContent.loader
        ) : (
          <Button
            disabled={!isDirectMode && !isConnected}
            onClick={() => handleClickAction(cardContent)}
          >
            {cardContent.name}
          </Button>
        )}
      </Stack>
      <Stack
        direction="row"
        padding=".875rem 1.5rem"
        borderRadius=".75rem"
        justifyContent="space-between"
        sx={{ backgroundColor: 'custom.neutral.black.2' }}
      >
        <Stack direction="row" gap="1rem">
          <Stack
            onClick={() => {
              if (allowRefresh) {
                handleClickReload(cardContent);
              }
            }}
          >
            <LoopIcon
              style={{
                width: '1.25rem',
                height: '1.25rem',
                cursor: allowRefresh ? 'pointer' : 'initial',
                color: allowRefresh
                  ? theme.palette.custom.primary[100]
                  : theme.palette.custom.neutral.black[30],
              }}
            />
          </Stack>
          <Text variant="titleM"> {cardContent.statusKey} :</Text>
        </Stack>
        <Text variant="titleM">{cardContent.status}</Text>
      </Stack>
    </Stack>
  );
};
