import React, { useCallback, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { ModalV2 as Modal } from '@lp/ds-next';
import { ColumnProps } from 'antd/lib/table';
import styled from 'styled-components';

import { Address } from './PartnerIdCard/Address';
import { usePartnerAddresses } from '../api/getPartnerAddresses';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import StyledTable from '@/components/tables/StyledTable';
import { scopes } from '@/config';
import { parseAntdSorter } from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import { usePagination } from '@/hooks/usePagination';
import { INITIAL_AddToPartnerAddress } from '@/models/address';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
`;

const LPPartnerAddressTable = ({ id }) => {
  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination({
      page: 1,
      pageSize: 10,
      orderDirection: 'DESC',
      orderColumn: 'updatedAt',
    });

  const [viewModalAddress, setViewModalAddress] = useState<any>();
  const [showModalAddress, setShowModalAddress] = useState<boolean>(false);

  const { data: partnerAddresses, isPending } = usePartnerAddresses({
    partnerId: id,
  });

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'addressId',
      'addressTypes',
      'company',
      'street',
      'street2',
      'building',
      'postalCode',
      'city',
      'countryCode',
      'email',
      'phoneNumber',
      'createdAt',
      'updatedAt',
      'position',
      'referenceId',
    ],

    defaultSort: {
      sortBy: paginationParams.orderColumn,
      order: paginationParams.orderDirection,
    },
    eyeLinkProps: {
      onClick: (record) => {
        setViewModalAddress(record);
        setShowModalAddress(true);
      },
    },
  });

  const handleTableChange = useCallback(
    ({ current: page, pageSize }, _filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      setPaginationParams({
        page,
        orderColumn: sortField,
        orderDirection: sortOrder,
        pageSize,
      });
    },
    [setPaginationParams]
  );

  const total = partnerAddresses?.total || 0;
  const pagination = {
    current: paginationParams.page,
    pageSize: paginationParams.pageSize,
    total,
    showSizeChanger: false,
  };

  return (
    <>
      <Wrapper>
        <ButtonWrapper>
          <ScopedCta
            component={PillButton}
            icon={<PlusOutlined />}
            onClick={() => {
              setViewModalAddress(INITIAL_AddToPartnerAddress);
              setShowModalAddress(true);
            }}
            requiredScopes={[scopes.EDIT_LPVILLAGE_PARTNER]}
            data-testid="addAddressBtn"
          >
            Add Address
          </ScopedCta>
        </ButtonWrapper>
        <StyledTable
          rowKey="id"
          dataSource={partnerAddresses?.items}
          columns={columns}
          size="small"
          pagination={pagination}
          loading={isPending}
          onChange={handleTableChange}
          scroll={{ x: '150%' }}
        />
      </Wrapper>
      <Modal
        open={showModalAddress}
        onClose={() => setShowModalAddress(!showModalAddress)}
        setOpen={setShowModalAddress}
        sx={{ minWidth: '70%' }}
        data-testid="partnerAddressModal"
      >
        <Address
          address={viewModalAddress}
          partnerId={id}
          handleClose={setShowModalAddress}
        />
      </Modal>
    </>
  );
};

export default LPPartnerAddressTable;
