import React from 'react';

import { Marker } from '@react-google-maps/api';

import useBoxLastLocation from '../hooks/useBoxLastLocation';
import SmallBoxImg from '@/assets/images/box.png';
import Loader from '@/components/Loader';
import Map from '@/components/Map';

const BoxLastLocationMap = ({
  boxId,
  thingName,
}: {
  boxId: string;
  thingName: string;
}) => {
  const { result } = useBoxLastLocation(boxId!, thingName);

  // TODO : result state in ENUM
  if (result.state === 'idle' || result.state === 'loading') {
    return <Loader size={32} />;
  }

  if (result.state === 'error') return <>Error fetching last location.</>;

  if (result.resource.events.length !== 1) return <>No last know location.</>;

  const boxPosition = {
    lat: parseFloat(result.resource.events[0].coordinate.latitude),
    lng: parseFloat(result.resource.events[0].coordinate.longitude),
  };

  return (
    <div>
      <Map center={boxPosition} zoom={10} height="30rem">
        <Marker icon={SmallBoxImg} position={boxPosition} />
      </Map>
    </div>
  );
};

export default BoxLastLocationMap;
