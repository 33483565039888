import React, { useState } from 'react';

import { ModalV2 as Modal } from '@lp/ds-next';

import { RemonteControlPanel } from './RemoteControlPanel';
import { IShipment } from '../../../../features/shipments/models/shipment';
import PillButton from '@/components/PillButton';

const RemoteControl = ({ shipment }: { shipment: IShipment }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <PillButton
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Remote control
      </PillButton>
      <Modal
        open={isOpen}
        setOpen={setIsOpen}
        onDismiss={() => {
          setIsOpen(false);
          window.location.reload();
        }}
      >
        <RemonteControlPanel isOpen={isOpen} shipment={shipment} />
      </Modal>
    </>
  );
};
export default RemoteControl;
