import React from 'react';

import { Grid, Switch, Text } from '@lp/ds-next';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';

import { IPartnerV3, IPartnerV3Update } from '../../../models/partnerV3';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const carriers = {
  CARRIER_CODE_UPS: 'UPS',
  CARRIER_CODE_FEDEX: 'FEDEX',
  CARRIER_CODE_TNTEXPRESS: 'TNTexpress',
  CARRIER_CODE_CHRONOPOST: 'CHRONOPOST',
  CARRIER_CODE_DHL: 'DHL',
  CARRIER_CODE_COLLISSIMO: 'COLISSIMO',
  CARRIER_CODE_MIIST: 'MIIST',
  CARRIER_CODE_OTHER: 'Other',
} as const;

const carriersList = Object.keys(carriers);

export const Carriers = ({
  partner,
  updatePartner,
}: {
  partner: IPartnerV3;
  updatePartner: (data: IPartnerV3Update) => Promise<{ success: boolean }>;
}) => {
  const addToastNotification = useToasts((state) => state.addItem);

  const selectAllCarriers = (event) => {
    if (event.target.checked) {
      updatePartner({ carriers: carriersList } as any);
    } else {
      addToastNotification(
        {
          msg: 'You cannot unselect all carriers. At least one is required.',
          type: MessageType.Error,
        },
        'root'
      );
    }
  };

  const toggleCarrier = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // Prevent unselecting all carriers
    if (partner.carriers?.length === 1 && !event.target.checked) {
      addToastNotification(
        {
          msg: 'At least one carrier must be selected.',
          type: MessageType.Error,
        },
        'root'
      );

      return;
    }

    // Use Set to ensure no duplicates
    const newCarriers = new Set(partner.carriers);

    if (event.target.checked) {
      newCarriers.add(event.target.name);
    } else {
      newCarriers.delete(event.target.name);
    }
    // Keep order in sync with intial list
    const orderedCarriers = carriersList.filter((carrier) =>
      newCarriers.has(carrier)
    );

    try {
      await updatePartner({
        carriers: orderedCarriers,
      } as any);
    } catch (error) {
      addToastNotification(
        {
          msg: 'An error happened while updating the partner, try again later',
          type: MessageType.Error,
        },
        'root'
      );
    }
  };

  return (
    <Grid item mobile={12} tablet={4}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel
          component="legend"
          sx={{ display: 'inline-flex', gap: '1rem', alignItems: 'center' }}
        >
          <Text variant="titleL" sx={{ display: 'inline' }}>
            Activated carriers
          </Text>
          <Switch
            checked={partner.carriers?.length === carriersList.length}
            onChange={selectAllCarriers}
          />
        </FormLabel>
        <FormGroup sx={{ mt: '1rem', gap: '0.5rem' }}>
          {carriersList.map((carrier) => (
            <Switch
              key={carrier}
              checked={partner.carriers?.includes(carrier) || false}
              onChange={toggleCarrier}
              label={carriers[carrier]}
              name={carrier}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};
