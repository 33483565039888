import React from 'react';

import { Navigate, RoutesProps } from 'react-router-dom';

import NotAllowed from '../views/NotAllowed';
import { appRoutes } from '@/configs/appRoutes';

interface ProtectedRouteProps {
  exact?: boolean;
  path: string;
  component?: any;
  authenticated: boolean;
  userScopes: string[];
  render?: any;
  requiredScopes?: string[];
  scopes?;
}

const ProtectedRoute = (props: RoutesProps & ProtectedRouteProps) => {
  const {
    authenticated,
    requiredScopes,
    userScopes,
    component: Component,
  } = props;

  if (authenticated) {
    if (requiredScopes?.some((val) => userScopes.includes(val))) {
      return <Component {...props} />;
    }

    return <NotAllowed />;
  }

  return <Navigate to={appRoutes.LOGIN} />;
};

export default ProtectedRoute;
