import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';

import { tracking } from '../config';
import { useAuth0 } from '@/shared/auth0';

interface IKlaviyoPayload {
  event?: string;
  customer_properties?: object;
  properties?: object;
}

interface IKlaviyoEvent {
  payload: IKlaviyoPayload;
  type: 'identify' | 'track';
}

interface IGAEcommEvent {
  contributionId: string;
  amount: string | number;
  parsedMultiplier: string | number;
  durationInMonth: string | number;
  paymentMethod: string;
}

export const SentryIdentify = (email: string) => {
  Sentry.configureScope(function (scope) {
    scope.setUser({ email });
  });
};

export const useTracking = () => {
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!tracking.TRACKING_ENABLED) {
      return;
    }
    ReactGA.plugin.require('ecommerce');
  }, []);

  useEffect(() => {
    if (isAuthenticated && user && user.email) {
      SentryIdentify(user.email);
    }
  }, [user, isAuthenticated]);
};

export const GAEvent = ({
  amount,
  paymentMethod,
}: {
  amount: string;
  paymentMethod: string;
}) => {
  try {
    ReactGA.event({
      category: 'bought',
      action: amount,
      label: paymentMethod,
    });
  } catch (err) {
    console.error('GA Event not sent', err);
  }
};

export const GAEcommEvent = ({
  contributionId,
  amount,
  parsedMultiplier,
  durationInMonth,
  paymentMethod,
}: IGAEcommEvent) => {
  try {
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id: contributionId,
      revenue: amount,
    });
    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id: contributionId,
      name: `${parsedMultiplier}x${durationInMonth}months`,
      sku: `${parsedMultiplier}x${durationInMonth}months`,
      price: amount,
      quantity: '1',
      category: paymentMethod,
    });
    ReactGA.plugin.execute('ecommerce', 'send', {});
  } catch (err) {
    console.error('GA Ecomm Event not sent', err);
  }
};

export const sendKlaviyoEvent = async ({ payload, type }: IKlaviyoEvent) => {
  const withToken = { token: tracking.KLAVIYO_API_KEY, ...payload };
  if (!tracking.TRACKING_ENABLED) {
    console.info(
      `Klaviyo ${type} event would have been sent with Payload:... `
    );
    console.info(payload);

    return { success: true };
  }
  const stringifiedPayload = JSON.stringify(withToken);
  const payloadB64 = Buffer.from(stringifiedPayload).toString('base64');
  const URL =
    type === 'identify'
      ? tracking.KLAVIYO_IDENTIFY_URL
      : tracking.KLAVIYO_TRACK_URL;
  try {
    const result = await fetch(`${URL}${payloadB64}`);
    const data = await result.json();
    if (data === 1) {
      return { success: true };
    }
    console.info('Klaviyo event failed');

    return { success: false };
  } catch (err: any) {
    console.info('Klaviyo event failed', err.toString());

    return { success: false, error: err };
  }
};
