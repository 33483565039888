import React from 'react';

import { Stack } from '@chakra-ui/react';
import {
  Text,
  Divider,
  Grid,
  NewTextField as TextField,
  PhoneNumber,
} from '@lp/ds-next';
import { Card, CardContent } from '@mui/material';
import { useFormikContext } from 'formik';

import { IPartnerV3 } from '../../models/partnerV3';

const OtherInformation = () => {
  const { handleChange, values, errors, setFieldValue } =
    useFormikContext<IPartnerV3>();

  return (
    <Card elevation={0} sx={{ borderRadius: '10px' }}>
      <CardContent>
        <Grid container direction="column">
          {/* ----- Header ----- */}
          <Grid item mobile={12}>
            <Stack direction="row" justifyContent="space-between">
              <Text variant="titleL" component="div">
                Other Information
              </Text>
            </Stack>
            <Divider sx={{ mt: '10px', mb: '20px' }} />
          </Grid>
          {/* ----- Main Information ----- */}
          <Grid item mobile={12} container direction="row" spacing={1}>
            <Grid item mobile={12}>
              <TextField
                onChange={handleChange}
                label="Description"
                name="description"
                value={values.description}
                error={!!errors.description}
                helperText={errors.description}
                multiline
                minRows={3}
                data-testid="partnerDescription"
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <PhoneNumber
                label="Phone number"
                defaultCountryCode="FR"
                name="phone_number"
                error={!!errors.phone_number}
                helperText={errors.phone_number}
                value={values.phone_number}
                onChange={(value) => {
                  setFieldValue('phone_number', value);
                }}
                data-testid="partnerPhoneNumber"
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OtherInformation;
